var player;
var videoId;

function onLoadPlayer() {
  player.removeEventListener('click', onLoadPlayer);
  player.classList.add('VideoPlayer--loading');
  var script = document.createElement('script');
  script.src = 'https://www.youtube.com/iframe_api';
  var firstScriptTag = document.querySelector('script');
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
}

export function initYouTubePlayer() {
  var _player$dataset;

  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '[data-youtube-player]';
  player = document.querySelector(selector);
  if (player === null) return;
  var videoLink = (_player$dataset = player.dataset) === null || _player$dataset === void 0 ? void 0 : _player$dataset.videoLink;
  if (videoLink === undefined) return;
  videoId = videoLink.split('/').pop();
  player.addEventListener('click', onLoadPlayer);
}

function onPlayerReady(event) {
  event.target.playVideo();
  player.classList.remove('VideoPlayer--loading');
  player.classList.add('VideoPlayer--loaded');
}

window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
  var container = player.querySelector('[data-youtube-player-container]');
  if (container === null) return null;
  return new window.YT.Player(container, {
    width: 1312,
    height: 611,
    videoId: videoId,
    events: {
      onReady: onPlayerReady
    }
  });
};