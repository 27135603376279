import get from 'lodash-es/get';
import { GLOBAL } from './constants';
export function getFromGlobalScope(path) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return get(GLOBAL.APP_ENTRY_POINT, path, defaultValue);
}
export function getFromLocale(key) {
  var _GLOBAL$APP_ENTRY_POI, _GLOBAL$APP_ENTRY_POI2;

  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return ((_GLOBAL$APP_ENTRY_POI = GLOBAL.APP_ENTRY_POINT) === null || _GLOBAL$APP_ENTRY_POI === void 0 ? void 0 : (_GLOBAL$APP_ENTRY_POI2 = _GLOBAL$APP_ENTRY_POI.translate) === null || _GLOBAL$APP_ENTRY_POI2 === void 0 ? void 0 : _GLOBAL$APP_ENTRY_POI2[key]) || defaultValue;
}
/* @source: https://github.com/emanuelsaringan/nl2br */

export function nl2br(str) {
  var isXhtml = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var breakTag = isXhtml ? '<br />' : '<br>';
  return String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1".concat(breakTag, "$2"));
}
export function convertToUnits(value) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'px';
  return "".concat(value).concat(unit);
}
export function toPx(value) {
  return convertToUnits(value, 'px');
}
export function clientToRect(clientPoint, rect) {
  var x = clientPoint.x,
      y = clientPoint.y;
  return {
    x: x - rect.left,
    y: y - rect.top
  };
}
export function getMedia(breakpointProperty) {
  var breakpointValue = window.getComputedStyle(window.document.documentElement).getPropertyValue(breakpointProperty);
  return window.matchMedia("(max-width: ".concat(parseInt(breakpointValue, 10) - 1, "px)"));
}
export function addClasses(element, classes) {
  classes.forEach(function (cls) {
    element.classList.add(cls);
  });
}
export function removeClasses(element, classes) {
  classes.forEach(function (cls) {
    element.classList.remove(cls);
  });
}