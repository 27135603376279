import Vue from 'vue/dist/vue';
import VueTheMask from 'vue-the-mask';
import { INPUT_MASKS } from '@library/scripts/constants';
import { ModalCloseMixin, ModalValidationMixin, PhoneInputHandlerMixin } from '../../scripts/mixins';
Vue.use(VueTheMask);
var modalSelectors = {
  feedback: '#modal-feedback',
  rent: '#modal-rent'
};
export var initModalFeedback = function initModalFeedback(el) {
  return new Vue({
    el: el,
    mixins: [PhoneInputHandlerMixin, ModalValidationMixin, ModalCloseMixin],
    name: el.id,
    data: function data() {
      return {
        phoneMask: INPUT_MASKS.PHONE_MASK_WITH_PARENTHESES,
        modalSelector: '#modal-feedback'
      };
    },
    beforeMount: function beforeMount() {
      this.modalSelector = modalSelectors[this.$el.dataset.mode];
    }
  });
};