export default {
  slidesPerView: 'auto',
  spaceBetween: 16,
  breakpoints: {
    425: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    640: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 24
    },
    1000: {
      slidesPerView: 4,
      slidesPerGroup: 4
    }
  }
};