function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Vue from 'vue/dist/vue';
import { Is } from '@library/scripts/is';
import Panzoom from '@panzoom/panzoom';
import { parseOptions } from 'uikit/src/js/util';
import UIkit from '../../vendor/uikit/uikit';
import { getFromGlobalScope, nl2br, toPx, clientToRect, getMedia, addClasses, removeClasses } from '../../scripts/helpers';
import { getUrlParameter } from '../../scripts/utils';
var NAVIGATION_OFFCANVAS_CLASSES = ['uk-offcanvas', 'uk-offcanvas-overlay', 'NavigationOffCanvas'];
var NAVIGATION_OFFCANVAS_BAR_CLASSES = ['uk-offcanvas-bar', 'NavigationOffCanvas__bar'];
export function initCategories(el, name) {
  return new Vue({
    el: el,
    name: name,
    data: function data() {
      return {
        shops: null,
        plan: null,
        tooltip: null,
        picture: null,
        map: null,
        navigation: null,
        ukNavigationOffCanvas: null,
        activePolygon: null,
        tooltipPosition: null,
        isPolygonHighlighted: false,
        isTooltipVisible: false,
        isLargeDown: false,
        isMediumDown: false,
        panzoom: {
          instance: null,
          options: null,
          panStartCoordinates: null,
          optionsLarge: {
            maxScale: 4,
            animate: true,
            duration: 400,
            contain: 'outside',
            overflow: 'visible',
            handleStartEvent: function handleStartEvent() {}
          },
          optionsSmall: {
            overflow: 'hidden',
            contain: null
          }
        }
      };
    },
    beforeMount: function beforeMount() {
      var _this = this;

      this.shops = getFromGlobalScope('binding.shops', []);
      this.plan = getFromGlobalScope('binding.plan', []);
      var shopSlug = getUrlParameter('shop', window.location.href);

      if (shopSlug !== '') {
        this.activePolygon = this.getPolygonBySlug(shopSlug);
      }

      var mediumMedia = getMedia('--uk-breakpoint-m');
      var largeMedia = getMedia('--uk-breakpoint-l');
      this.isMediumDown = mediumMedia.matches;
      this.isLargeDown = largeMedia.matches;
      this.updatePanzoomOptions();
      mediumMedia.addListener(function (e) {
        _this.isMediumDown = e.matches;
        _this.tooltipPosition = {};
      });
      largeMedia.addListener(function (e) {
        _this.isLargeDown = e.matches;

        _this.updatePanzoomOptions();
      });
    },
    mounted: function mounted() {
      var _this2 = this;

      var _this$$refs = this.$refs,
          tooltip = _this$$refs.tooltip,
          picture = _this$$refs.picture,
          map = _this$$refs.map,
          navigation = _this$$refs.navigation,
          image = _this$$refs.image,
          svgMap = _this$$refs.svgMap;

      if (!Is.undefined(tooltip)) {
        tooltip.addEventListener('touchmove', function (event) {
          event.preventDefault();
        });
        this.tooltip = this.$refs.tooltip;
      }

      if (!Is.undefined(picture)) {
        this.picture = this.$refs.picture;
      }

      var applyImageSize = function applyImageSize(height, width) {
        svgMap.setAttribute('viewBox', "0 0 ".concat(width, " ").concat(height));
        svgMap.removeAttribute('style');

        if (!Is.undefined(map)) {
          _this2.map = map;
          _this2.panzoom.instance = Panzoom(map, _objectSpread({}, _this2.panzoom.options));

          if (_this2.isLargeDown && !Is.null(_this2.activePolygon)) {
            setTimeout(function () {
              return _this2.scrollToPolygon();
            }, 50);
          }
        }
      };

      if (!Is.undefined(svgMap) && !Is.undefined(image)) {
        image.addEventListener('load', function () {
          var imageSource = image.getAttribute('src');

          if (imageSource.endsWith('svg')) {
            fetch(imageSource).then(function (res) {
              return res.text();
            }).then(function (content) {
              var parser = new DOMParser();
              var dom = parser.parseFromString(content, 'image/svg+xml');
              var svg = dom.querySelector('svg');
              var width = svg.getAttribute('width');
              var height = svg.getAttribute('height');
              applyImageSize(height, width);
            });
          } else {
            var width = image.naturalWidth,
                height = image.naturalHeight;
            applyImageSize(width, height);
          }
        });
      }

      if (!Is.undefined(navigation)) {
        this.navigation = navigation;
      }

      this.highlightPolygon();
    },
    computed: {
      meta: function meta() {
        var _this$activePolygon;

        return (_this$activePolygon = this.activePolygon) === null || _this$activePolygon === void 0 ? void 0 : _this$activePolygon.meta;
      },
      timeWork: function timeWork() {
        var _this$meta, _this$meta$descriptio;

        var timeWork = (_this$meta = this.meta) === null || _this$meta === void 0 ? void 0 : (_this$meta$descriptio = _this$meta.description) === null || _this$meta$descriptio === void 0 ? void 0 : _this$meta$descriptio.time_work; // eslint-disable-line camelcase

        return !Is.undefined(timeWork) ? nl2br(timeWork) : timeWork;
      },
      description: function description() {
        var _this$meta2, _this$meta2$descripti;

        return (_this$meta2 = this.meta) === null || _this$meta2 === void 0 ? void 0 : (_this$meta2$descripti = _this$meta2.description) === null || _this$meta2$descripti === void 0 ? void 0 : _this$meta2$descripti.description;
      },
      logo: function logo() {
        var _this$meta3, _this$meta3$descripti;

        return (_this$meta3 = this.meta) === null || _this$meta3 === void 0 ? void 0 : (_this$meta3$descripti = _this$meta3.description) === null || _this$meta3$descripti === void 0 ? void 0 : _this$meta3$descripti.logo;
      },
      slug: function slug() {
        var _this$meta4, _this$meta4$descripti;

        return (_this$meta4 = this.meta) === null || _this$meta4 === void 0 ? void 0 : (_this$meta4$descripti = _this$meta4.description) === null || _this$meta4$descripti === void 0 ? void 0 : _this$meta4$descripti.slug;
      },
      polygonRef: function polygonRef() {
        return this.$refs["polygon-".concat(this.slug)];
      }
    },
    methods: {
      getPolygonBySlug: function getPolygonBySlug(slug) {
        return this.plan.polygons.find(function (polygon) {
          var _polygon$meta, _polygon$meta$descrip;

          return ((_polygon$meta = polygon.meta) === null || _polygon$meta === void 0 ? void 0 : (_polygon$meta$descrip = _polygon$meta.description) === null || _polygon$meta$descrip === void 0 ? void 0 : _polygon$meta$descrip.slug) === slug;
        }) || null;
      },
      scrollToPolygon: function scrollToPolygon() {
        if (Is.undefined(this.polygonRef) || Is.null(this.map) || Is.null(this.panzoom.instance)) {
          return;
        }

        var polygonRect = this.polygonRef.getBoundingClientRect();
        var mapRect = this.map.getBoundingClientRect();
        var mapViewRect = this.map.parentElement.getBoundingClientRect();
        var viewportWidth = window.document.documentElement.clientWidth;
        var offsetX = -polygonRect.left + mapRect.left + viewportWidth / 2 - polygonRect.width / 2; // Polygon y coordinate relative to mapview block

        var _clientToRect = clientToRect({
          x: polygonRect.x,
          y: polygonRect.y
        }, mapViewRect),
            polygonY = _clientToRect.y; // Map y coordinate relative to mapview block


        var _clientToRect2 = clientToRect({
          x: mapRect.x,
          y: mapRect.y
        }, mapViewRect),
            mapY = _clientToRect2.y;

        var offsetY = -polygonY + mapY + mapViewRect.height / 2 - polygonRect.height / 2;
        this.panzoom.instance.pan(offsetX, offsetY);
      },
      onPolygonDragStart: function onPolygonDragStart(event) {
        // Safari and Firefox do not have `TouchEvent`.
        if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent) {
          if (event.touches.length !== 1) {
            return;
          }

          this.panzoom.panStartCoordinates = {
            x: event.touches[0].clientX,
            y: event.touches[0].clientY
          };
        } else if (event instanceof MouseEvent) {
          this.panzoom.panStartCoordinates = {
            x: event.clientX,
            y: event.clientY
          };
        }
      },
      onPolygonDragEnd: function onPolygonDragEnd(event) {
        var panStartCoordinates = this.panzoom.panStartCoordinates;
        this.panzoom.panStartCoordinates = null;
        var x = 0;
        var y = 0; // Safari and Firefox do not have `TouchEvent`.

        if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent) {
          if (event.changedTouches.length !== 1) {
            return;
          }

          x = event.changedTouches[0].clientX;
          y = event.changedTouches[0].clientY;
        } else if (event instanceof MouseEvent) {
          x = event.clientX;
          y = event.clientY;
        } else {
          return;
        }

        if (panStartCoordinates === null) return;
        var startX = panStartCoordinates.x,
            startY = panStartCoordinates.y;

        if (x === startX && y === startY) {
          this.onPolygonClick(event);
        }
      },
      onPolygonClick: function onPolygonClick(event) {
        this.isTooltipVisible = false;
        this.onShopItemClick(event, {
          scrollToPolygon: false
        });
        this.showTooltip();
      },
      onShopItemClick: function onShopItemClick(event) {
        var _options$scrollToPoly;

        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var scrollToPolygon = (_options$scrollToPoly = options.scrollToPolygon) !== null && _options$scrollToPoly !== void 0 ? _options$scrollToPoly : true;
        this.isPolygonHighlighted = false;
        var target = event.target;
        var linkEl = target.closest('a');
        var url = linkEl.matches('svg a') ? linkEl.href.baseVal : linkEl.href;
        window.history.replaceState({}, document.title, url);
        var slug = linkEl.dataset.shopSlug;

        if (Is.null(slug)) {
          return;
        }

        this.activePolygon = this.getPolygonBySlug(slug);

        if (this.isLargeDown) {
          if (scrollToPolygon) {
            this.scrollToPolygon();
          }

          if (!Is.null(this.ukNavigationOffCanvas)) {
            this.ukNavigationOffCanvas.hide();
          }
        }

        this.highlightPolygon();
      },
      closeTooltip: function closeTooltip() {
        this.isTooltipVisible = false;
      },
      setTooltipPosition: function setTooltipPosition(point) {
        this.tooltipPosition = {
          top: toPx(point.y),
          left: toPx(point.x)
        };
      },
      placeTooltip: function placeTooltip(callback) {
        var _this3 = this;

        Vue.nextTick(function () {
          var mainRect = _this3.$el.getBoundingClientRect();

          var mapViewRect = _this3.map.parentElement.getBoundingClientRect();

          var polygonEl = _this3.polygonRef;

          if (Is.undefined(polygonEl)) {
            return;
          }

          var x = mapViewRect.left + mapViewRect.width / 2;
          var y = mainRect.height / 2;

          _this3.setTooltipPosition({
            x: x,
            y: y
          });

          callback();
        });
      },
      showTooltip: function showTooltip() {
        var _this4 = this;

        if (Is.null(this.tooltip) || Is.null(this.activePolygon)) {
          return;
        }

        if (!this.isMediumDown) {
          this.placeTooltip(function () {
            _this4.isTooltipVisible = true;
          });
        } else {
          this.isTooltipVisible = true;
        }
      },
      highlightPolygon: function highlightPolygon() {
        if (Is.null(this.activePolygon)) {
          return;
        }

        this.isPolygonHighlighted = true;
      },
      zoomIn: function zoomIn() {
        this.panzoom.instance.zoomIn();
      },
      zoomOut: function zoomOut() {
        this.panzoom.instance.zoomOut();
      },
      onWheel: function onWheel(event) {
        this.panzoom.instance.zoomWithWheel(event);
      },
      updatePanzoomOptions: function updatePanzoomOptions() {
        var _this$panzoom$instanc;

        var _this$panzoom = this.panzoom,
            optionsSmall = _this$panzoom.optionsSmall,
            optionsLarge = _this$panzoom.optionsLarge;
        this.panzoom.options = this.isLargeDown ? _objectSpread({}, optionsLarge, {}, optionsSmall) : _objectSpread({}, optionsSmall, {}, optionsLarge);
        return (_this$panzoom$instanc = this.panzoom.instance) === null || _this$panzoom$instanc === void 0 ? void 0 : _this$panzoom$instanc.setOptions(this.panzoom.options);
      },
      initNavigationOffCanvas: function initNavigationOffCanvas() {
        if (Is.undefined(this.navigation)) {
          return;
        }

        var options = parseOptions(this.navigation.dataset.navigationOffcanvas);
        var bar = this.navigation.querySelector('[data-offcanvas-bar]');
        addClasses(this.navigation, NAVIGATION_OFFCANVAS_CLASSES);
        addClasses(bar, NAVIGATION_OFFCANVAS_BAR_CLASSES);
        this.ukNavigationOffCanvas = UIkit.offcanvas(this.navigation, options);

        if (this.navigation.dataset.open === 'true') {
          this.ukNavigationOffCanvas.show();
          this.navigation.dataset.open = 'false';
        }
      },
      destroyNavigationOffCanvas: function destroyNavigationOffCanvas() {
        if (Is.undefined(this.navigation)) {
          return;
        }

        var bar = this.navigation.querySelector('[data-offcanvas-bar]');
        removeClasses(this.navigation, NAVIGATION_OFFCANVAS_CLASSES);
        removeClasses(bar, NAVIGATION_OFFCANVAS_BAR_CLASSES);
        this.ukNavigationOffCanvas.$destroy();
        this.ukNavigationOffCanvas = null;
      }
    },
    watch: {
      isLargeDown: function isLargeDown(value) {
        if (value) {
          this.initNavigationOffCanvas();
          return;
        }

        this.destroyNavigationOffCanvas();
      }
    }
  });
}