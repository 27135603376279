export function initBrandList() {
  var SLIDE_SPEED = 2;
  var tape = document.querySelector('[data-brands-tape]');
  var slider = document.querySelector('[data-brands-slider]');

  if (tape === null || slider === null) {
    return;
  }

  var slides = tape.querySelectorAll('[data-brands-tape-slide]');
  var slidesCount = slides.length;

  if (slidesCount === 0) {
    return;
  }

  var slideWidth = 242;
  var documentWidth = document.documentElement.clientWidth;
  var slidesVisible = Math.ceil(documentWidth * 2 / slideWidth);

  if (slidesCount >= slidesVisible) {
    slidesVisible += slidesCount;
  }

  while (slidesCount < slidesVisible) {
    var counter = 0;
    slides.forEach(function (el) {
      tape.appendChild(el.cloneNode(true));
      counter += 1;
    });
    slidesCount += counter;
  }

  slider.style.animationDuration = "".concat(slidesCount * SLIDE_SPEED, "s");
  slider.appendChild(tape.cloneNode(true));
}