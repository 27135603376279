export default {
  slidesPerView: 'auto',
  spaceBetween: 16,
  breakpoints: {
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 24
    },
    1000: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 48
    }
  }
};