import Vue from 'vue/dist/vue';
import VueTheMask from 'vue-the-mask';
import { INPUT_MASKS } from '@library/scripts/constants';
import { ModalCloseMixin, ModalValidationMixin, PhoneInputHandlerMixin } from '../../scripts/mixins';
import { getFromLocale } from '../../scripts/helpers';
Vue.use(VueTheMask);
var LABEL_FILE_DEFAULT = getFromLocale('fileAttachDefault');
export var initModalRent = function initModalRent(el) {
  return new Vue({
    el: el,
    mixins: [PhoneInputHandlerMixin, ModalValidationMixin, ModalCloseMixin],
    name: el.id,
    data: function data() {
      return {
        phoneMask: INPUT_MASKS.PHONE_MASK_WITH_PARENTHESES,
        modalSelector: '#modal-rent'
      };
    },
    mounted: function mounted() {
      this.initInputFile(this.$el);
    },
    methods: {
      initInputFile: function initInputFile(modal) {
        var fileFields = modal.querySelectorAll('[data-field-file]');
        if (fileFields.length > 0) fileFields.forEach(function (field) {
          var input = field.querySelector('[data-input-file]');
          var label = field.querySelector('[data-label-file]');
          var closeButton = field.querySelector('[data-button-attach]');
          input.addEventListener('change', function () {
            label.textContent = input.files[0].name;
            input.classList.add('SiteForm__input--fileAttached');
          });
          closeButton.addEventListener('click', function () {
            input.classList.remove('SiteForm__input--fileAttached');
            label.textContent = LABEL_FILE_DEFAULT;
          });
        });
      },
      closeModalRent: function closeModalRent() {
        var inputFile = this.$el.querySelectorAll('[data-input-file]');
        var labelFile = this.$el.querySelectorAll('[data-label-file]');
        inputFile.forEach(function (input) {
          input.classList.remove('SiteForm__input--fileAttached');
        });
        labelFile.forEach(function (label) {
          label.textContent = LABEL_FILE_DEFAULT;
        });
        this.closeModal();
      }
    }
  });
};