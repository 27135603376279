function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Swiper, Keyboard, Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Is } from '@library/scripts/is';
import has from 'lodash-es/has';
Swiper.use([Keyboard, Navigation, Pagination, A11y, Autoplay]);
export var CLASSES = {
  WRAPPER: '.swiper-wrapper',
  CONTAINER: '.swiper-container',
  SLIDE: '.swiper-slide'
};
export var SELECTORS = {
  ARROW_PREV: '[data-slider-arrow-prev]',
  ARROW_NEXT: '[data-slider-arrow-next]',
  PAGINATION: '[data-slider-pagination]'
};
/**
 * Gets HTML DOM Element
 * @param {string|Element} $element
 * @return Element|null
 */

function getNode($element) {
  if (Is.string($element)) {
    return document.querySelector($element);
  }

  if (Is.instanceOf($element, HTMLElement)) {
    return $element;
  }

  return null;
}
/**
 * Default Swiper options
 */


export var defaultOptions = {
  loop: false,
  speed: 650,
  spaceBetween: 0,
  slidesPerView: 'auto',
  navigation: true,
  pagination: true
};
/**
 * Default navigation options
 * @param {string|Element} element
 * @return Object|false
 */

export function navigation(element) {
  var $element = getNode(element);
  if (!element) return false;
  var prev = $element.querySelector(SELECTORS.ARROW_PREV);
  var next = $element.querySelector(SELECTORS.ARROW_NEXT);
  if (!prev || !next) return false;
  return {
    nextEl: next,
    prevEl: prev
  };
}
/**
 * Default pagination options
 * @param {string|Element} element
 * @return Object|false
 */

export function pagination(element) {
  var $element = getNode(element);
  if (!$element) return false;
  var $pagination = $element.querySelector(SELECTORS.PAGINATION);
  if (!$pagination) return false;
  return {
    el: $pagination,
    type: 'bullets',
    clickable: true,
    renderBullet: function renderBullet(index, className) {
      return "<li class=\"".concat(className, "\"><span>").concat(index + 1, "</span></li>");
    }
  };
}

function updateProperty(instance, options, property, callback) {
  if (has(options, property) && options[property] === true) {
    if (options[property] === true) options[property] = callback(instance);

    if (Is.object(options[property])) {
      var callbackOptions = callback(instance);
      var thisOptions = options[property];
      options[property] = _objectSpread({}, callbackOptions, {}, thisOptions);
    }
  }
}
/**
 * Combine navigation, pagination properties with default values
 * @param {Element} container
 * @param {Object} options
 * @return Object
 */


function updateProperties(container, options) {
  updateProperty(container, options, 'pagination', pagination);
  updateProperty(container, options, 'navigation', navigation); // Disable looping and swiping when slides count <= 1

  var slidesCount = container.querySelectorAll(CLASSES.SLIDE).length;
  options.loop = options.loop && slidesCount > 1;
  options.allowSlidePrev = options.allowSlidePrev || slidesCount > 1;
  options.allowSlideNext = options.allowSlideNext || slidesCount > 1;
  return options;
}
/**
 * Initialize Swiper instance
 * @param {string|Element} $element
 * @param {Object|null} options
 * @return {Object|null} swiper instance
 */


export function initializer($element, options) {
  $element = getNode($element);
  if (!$element) return null;
  var $container = $element.querySelector(CLASSES.CONTAINER);
  if (!$container) return null;
  var datasetOptions = {};
  options = _objectSpread({}, defaultOptions, {}, options, {}, datasetOptions);
  options = updateProperties($element, options);
  return new Swiper($container, options);
}
/**
 * Initialize Swiper slider instances
 * @param {{ selector: string, options: Object|null }[]} instances
 */

export function initSwiperInstances(instances) {
  instances.forEach(function (_ref) {
    var selector = _ref.selector,
        options = _ref.options;
    document.querySelectorAll(selector).forEach(function (instance) {
      initializer(instance, options || {});
    });
  });
}