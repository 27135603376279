/* @source: https://blog.bitscry.com/2018/08/17/getting-and-setting-url-parameters-with-javascript */
export function setUrlParameter(key, value) {
  var url = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : window.location.toString();
  var urlQueryString = '';
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);
  var baseUrl = url.split('?')[0];
  var newParam = "".concat(key, "=").concat(value);
  var params = "?".concat(newParam);

  if (url.split('?')[1] !== undefined) {
    // if there are no query strings, make urlQueryString empty
    urlQueryString = "?".concat(url.split('?')[1]);
  } // If the "search" string exists, then build params from it


  if (urlQueryString) {
    var updateRegex = new RegExp("([?&])".concat(key, "=[^&]*"));
    var removeRegex = new RegExp("([?&])".concat(key, "=[^&;]+[&;]?"));

    if (value === undefined || value === null || value === '') {
      // Remove param if value is empty
      params = urlQueryString.replace(removeRegex, '$1');
      params = params.replace(/[&;]$/, '');
    } else if (urlQueryString.match(updateRegex) !== null) {
      // If param exists already, update it
      params = urlQueryString.replace(updateRegex, "$1".concat(newParam));
    } else if (urlQueryString === '') {
      // If there are no query strings
      params = "?".concat(newParam);
    } else {
      // Otherwise, add it to end of query string
      params = "".concat(urlQueryString, "&").concat(newParam);
    }
  } // no parameter was set so we don't need the question mark


  params = params === '?' ? '' : params;
  return baseUrl + params;
}
/* @source: https://blog.bitscry.com/2018/08/17/getting-and-setting-url-parameters-with-javascript */

export function getUrlParameter(parameter) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.toString();
  parameter = parameter.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp("[\\?|&]".concat(parameter.toLowerCase(), "=([^&#]*)"));
  var results = regex.exec("?".concat(url.toLowerCase().split('?')[1]));
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ''));
}