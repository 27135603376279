import { initVueInstances } from '@library/scripts/register';
import '@library/picture/picture';
import initMaps from '@library/maps/yandex-map/yandex-map';
import UIkit from './vendor/uikit/uikit';
import { initSwiperInstances } from './vendor/swiper/swiper';
import promoSliderOptions from './components/promo-slider/options';
import eventsSliderOptions from './components/events-slider/options';
import newsSliderOptions from './components/news-slider/options';
import moviesSliderOptions from './components/movies-slider/options';
import shopGalleryOptions from './components/shop-gallery/options';
import { initMobileBurger } from './components/header/header';
import { initYouTubePlayer } from './components/video/video';
import { initToggleVacancyBody } from './components/vacancy-card/vacancy-card';
import { initBrandList } from './components/brand-slider/brand-slider';
import { initTicker } from './components/ticker/ticker';
import { initCategories } from './pages/category/category';
import { getFromGlobalScope } from './scripts/helpers';
import { GLOBAL } from './scripts/constants';
import { initModalSubscribe, turnModalSubscribe } from './components/modal-subscribe/modal-subscribe';
import { initModalFeedback } from './components/modal-feedback/modal-feedback';
import { initModalRent } from './components/modal-rent/modal-rent';
import { initSearchForm } from './components/header/search-form';
window.UIkit = UIkit;
document.addEventListener('DOMContentLoaded', function () {
  initSwiperInstances([{
    selector: '[data-promo-slider]',
    options: promoSliderOptions
  }, {
    selector: '[data-events-slider]',
    options: eventsSliderOptions
  }, {
    selector: '[data-news-slider]',
    options: newsSliderOptions
  }, {
    selector: '[data-movies-slider]',
    options: moviesSliderOptions
  }, {
    selector: '[data-shop-gallery]',
    options: shopGalleryOptions
  }]);
  initMaps(GLOBAL.APP_ENTRY_POINT, 'window.sdc-web');
  initMobileBurger();
  initYouTubePlayer();
  initToggleVacancyBody();
  initBrandList();
  initTicker();
  initVueInstances([{
    query: '[data-feedback-modal]',
    init: initModalFeedback
  }, {
    query: '[data-rent-modal]',
    init: initModalRent
  }, {
    query: '[data-subscribe-modal]',
    init: initModalSubscribe
  }, {
    query: '[data-categories]',
    init: initCategories
  }, {
    query: '[data-search-form]',
    init: initSearchForm
  }]);
  turnModalSubscribe();
});
document.addEventListener('ymaps-initialized', function (event) {
  var map = event.detail.map; // Adjust zoom control position

  map.controls.remove('zoomControl');
  map.controls.add('zoomControl', {
    position: {
      top: 108,
      right: 10,
      bottom: 'auto',
      left: 'auto'
    }
  }); // Remove geolocation control

  map.controls.remove('geolocationControl');

  if (window.innerWidth < 900) {
    var coordinates = getFromGlobalScope('coordinates');
    map.setCenter([coordinates.latitude, coordinates.longitude]);
  }
});