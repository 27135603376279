export function initToggleVacancyBody() {
  document.querySelectorAll('[data-toggle-vacancy]').forEach(function (item) {
    if (!item) return;
    var toggle = false;
    var id = item.dataset.toggleVacancy;
    var body = document.querySelector("[data-vacancy-body=\"".concat(id, "\"]"));
    if (!body) return;
    item.addEventListener('click', function () {
      toggle = !toggle;
      body.style.display = toggle ? 'block' : 'none';

      if (toggle) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  });
}