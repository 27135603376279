import Vue from 'vue/dist/vue';
import VueTheMask from 'vue-the-mask';
import { INPUT_MASKS } from '@library/scripts/constants';
import { Is } from '@library/scripts/is';
import { ModalCloseMixin, ModalValidationMixin, PhoneInputHandlerMixin } from '../../scripts/mixins';
Vue.use(VueTheMask);
var MS_PER_DAY = 86400000;
var INTERVAL_POPUP = 30000; // 30s

var TODAY = Math.floor(Date.now() / MS_PER_DAY);

var showPopUp = function showPopUp() {
  var _window$UIkit;

  var popUp = (_window$UIkit = window.UIkit) === null || _window$UIkit === void 0 ? void 0 : _window$UIkit.modal('[data-subscribe-modal]');
  if (Is.undefined(popUp)) return;
  popUp.show();
  localStorage.setItem('lastShowPopUp', "".concat(TODAY));
};

var isTimeToShowPopup = function isTimeToShowPopup() {
  var lastShowPopUp = localStorage.getItem('lastShowPopUp');

  if (Is.null(localStorage.getItem('isSubscribed'))) {
    return Is.null(lastShowPopUp) || Math.abs(TODAY - lastShowPopUp) >= 1;
  }

  return false;
};

export var turnModalSubscribe = function turnModalSubscribe() {
  setTimeout(function () {
    if (isTimeToShowPopup()) showPopUp();
  }, INTERVAL_POPUP);
};
export var initModalSubscribe = function initModalSubscribe(el) {
  return new Vue({
    el: el,
    mixins: [PhoneInputHandlerMixin, ModalValidationMixin, ModalCloseMixin],
    name: el.id,
    data: function data() {
      return {
        phoneMask: INPUT_MASKS.PHONE_MASK_WITH_PARENTHESES,
        modalSelector: '#modal-subscribe'
      };
    },
    methods: {
      setSubscribe: function setSubscribe() {
        localStorage.setItem('isSubscribed', 'true');
      }
    }
  });
};