function parseTextArray(textArray) {
  return JSON.parse(textArray).map(function (chunk) {
    return chunk.word;
  }).join('');
}

export function initTicker() {
  var FONT_SIZE = 48;
  var ticker = document.querySelector('[data-promo-ticker]');

  if (ticker === null) {
    return;
  }

  var curve = ticker.querySelector('[data-ticker-curve]');
  var textContainer = ticker.querySelector('[data-text-container]');
  var message = parseTextArray(ticker.dataset.textArray);
  var requiredMessageLength = 3 * curve.getTotalLength() / FONT_SIZE;
  var timesToClone = Math.ceil(requiredMessageLength / message.length);

  for (var i = 0; i < timesToClone; i += 1) {
    var clone = textContainer.cloneNode(true);
    textContainer.parentElement.appendChild(clone);
  }
}