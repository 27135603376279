'use strict';

import getter from 'lodash-es/get';
/**
 * @typedef {string} YandexMapsOnLoadFunctionPath
 * https://tech.yandex.ru/maps/doc/jsapi/2.1/dg/concepts/load-docpage#load__param_onload
 * @example
 * window.qq.some-path
 */

/**
 * Initialize all defined maps
 * @param {Object} globals - global storage object
 * @param {YandexMapsOnLoadFunctionPath} globalsPath - globals object path
 * @param {function} initMapCallback
 */

export default function (globals, globalsPath, initMapCallback = initMap) {
  const script = document.createElement('script');
  const src = new URL('https://api-maps.yandex.ru/2.1/'); // TODO global l10n fallback and some

  let lang = document.documentElement.getAttribute('lang');
  if (!lang) lang = 'ru';
  const ym = {
    lang: {
      'ru': 'ru_RU',
      'en': 'en_US',
      'fallback': 'en_RU'
    }
  };
  if (!globals.maps) globals.maps = {};
  if (!globals.maps.instances) globals.maps.instances = {};

  if (Object.keys(globals.maps.instances).length > 0) {
    if (globals.yandexMapApiKey) {
      src.searchParams.set('apikey', globals.yandexMapApiKey);
    }

    src.searchParams.set('lang', ym.lang[lang] || ym.lang['fallback']);
    src.searchParams.set('load', 'package.standard');
    src.searchParams.set('onload', `${globalsPath}.maps.initAll`);
    script.src = src.toString();
    script.async = true;
    document.body.appendChild(script);
  }

  const maps = globals.maps;

  maps.initAll = function () {
    Object.keys(maps.instances).forEach(key => {
      const map = maps.instances[key];
      const config = {
        // configure popup balloon
        placemarkConfig: {
          balloonShadow: false,
          balloonOffset: [3, -50],
          balloonPanelMaxMapArea: 0,
          hideIconOnBalloonOpen: false
        },
        // default icon settings, only layout and color if config is not present
        // https://tech.yandex.com/maps/jsapi/doc/2.1/ref/reference/option.presetStorage-docpage
        iconLayout: getter(map, 'config.iconSettings.iconLayout', 'islands#icon'),
        iconColor: getter(map, 'config.iconSettings.iconColor', 'blue'),
        // clusterer config
        // https://tech.yandex.com/maps/jsapi/doc/2.1/ref/reference/Clusterer-docpage
        clustererConfig: getter(map, 'config.clustererConfig', {}),
        // disable 'drag' on touch devices
        disableTouchDevicesDrag: getter(map, 'config.disableTouchDevicesDrag', true)
      }; // fill other icon parameters if present

      const fields = ['iconImageHref', 'iconImageSize', 'iconImageOffset'];
      fields.forEach(key => {
        if (getter(map, `config.iconSettings.${key}`)) config[key] = map.config.iconSettings[key];
      });
      initMapCallback(map.id, map.zoom, map.center, map.points, config);
    });
  };
}
/**
 * Coordinates array
 * @typedef {[Number, Number]} Coordinates - latitude, longitude
 */

/**
 * Yandex Maps placemark
 * @typedef {Object} Placemark
 * @property {string} title - balloon header
 * @property {HTML} description - balloon content
 * @property {Number} latitude - latitude of placemark
 * @property {Number} longitude - longitude of placemark
 */

/**
 * @typedef {Object} PlacemarkOptions - https://tech.yandex.ru/maps/jsapi/doc/2.1/ref/reference/Placemark-docpage/#Placemark__param-options
 */

/**
 * Init Yandex Maps instance
 * @param {string} elementID - ID attribute of HTML element
 * @param {Number} [zoom=14] - zoom value for map instance
 * @param {Coordinates} center - map center
 * @param {Placemark[]} placemarks - array of placemark points on map
 * @param {PlacemarkOptions} options - configuration object for placemark
 */

export function initMap(elementID, zoom = 14, center = [0, 0], placemarks, options) {
  function getCenter() {
    const firstPlacemark = placemarks.length === 1 ? placemarks[0] : null;

    if (firstPlacemark && center[0] === 0 && center[1] === 0) {
      return [firstPlacemark.latitude, firstPlacemark.longitude];
    } else {
      return center;
    }
  }

  const mapCenter = getCenter();
  const yandexMapOptions = {
    controls: ['fullscreenControl', 'geolocationControl', 'zoomControl'],
    center: mapCenter,
    zoom
  };
  const ymaps = window['ymaps'];
  const clusterer = new ymaps.Clusterer(options.clustererConfig);
  const map = new ymaps.Map(elementID, yandexMapOptions);
  map.behaviors.disable('scrollZoom');

  if (('ontouchstart' in window || navigator.msMaxTouchPoints) && options.disableTouchDevicesDrag) {
    map.behaviors.disable('drag');
  }

  if (placemarks.length > 0) {
    const geoObjects = placemarks.map(placemark => new ymaps.Placemark( // point geometry
    [placemark.latitude, placemark.longitude], // properties, balloon and icon content
    {
      balloonContentHeader: placemark.title,
      balloonContent: placemark.description
    }, // balloon style, other icon settings, etc.
    options));
    clusterer.add(geoObjects);
    map.geoObjects.add(clusterer);

    if (geoObjects.length > 1 && mapCenter[0] === 0 && mapCenter[1] === 0) {
      map.setBounds(map.geoObjects.getBounds(), {
        zoomMargin: 100
      });
    }
  }

  document.dispatchEvent(new CustomEvent('ymaps-initialized', {
    detail: {
      id: elementID,
      map: map
    }
  }));
}