import { Is } from '@library/scripts/is';
import { getFromLocale } from './helpers';
var EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
export var PhoneInputHandlerMixin = {
  methods: {
    onPhoneInput: function onPhoneInput(event) {
      var currentValue = event.currentTarget.value;

      if (/^[8]/.test(currentValue)) {
        event.currentTarget.value = currentValue.replace(/^[8]/g, '+7');
      }

      if (currentValue.length < 18) {
        event.currentTarget.setCustomValidity(getFromLocale('invalidPhone'));
      } else {
        event.currentTarget.setCustomValidity('');
      }

      this.setCustomValidationMessage(event.target);
    },
    getPhoneValue: function getPhoneValue(container) {
      var phoneSelector = '[data-form-phone]';
      var phone = container.querySelector(phoneSelector);

      if (phone) {
        return phone.value;
      }

      return null;
    }
  }
};
export var ModalValidationMixin = {
  methods: {
    setCustomValidationMessage: function setCustomValidationMessage(input) {
      var isEmailField = input.type === 'email';

      if (isEmailField ? !EMAIL_REGEXP.test(input.value) : !input.validity.valid) {
        input.classList.add('SiteForm__input--invalid');
        var message = isEmailField ? 'Адрес электронной почты должен иметь вид "xxxx@xxx.xx"' : input.validationMessage;
        var parent = input.parentNode;
        parent.querySelector('[data-form-error]').textContent = message;
      } else {
        input.classList.remove('SiteForm__input--invalid');
      }
    },
    onInvalid: function onInvalid(evt) {
      evt.preventDefault();
      this.setCustomValidationMessage(evt.target);
    },
    onInput: function onInput(evt) {
      if (evt.target.checkValidity()) this.setCustomValidationMessage(evt.target);
    }
  }
};
export var ModalCloseMixin = {
  methods: {
    closeModal: function closeModal() {
      var _window$UIkit;

      var modal = (_window$UIkit = window.UIkit) === null || _window$UIkit === void 0 ? void 0 : _window$UIkit.modal(this.modalSelector);
      if (Is.undefined(modal)) return;
      modal.hide();
    }
  }
};