import UIkit from '../../vendor/uikit/uikit';

function hideOffcanvasOnNavigationClick(offcanvas) {
  offcanvas.querySelectorAll('a').forEach(function (link) {
    link.addEventListener('click', function () {
      UIkit.offcanvas(offcanvas).hide();
    });
  });
}

export function initMobileBurger() {
  var offcanvas = document.querySelector('[data-mobile-offcanvas]');
  var burger = document.querySelectorAll('[data-mobile-burger]');

  if (!burger || !offcanvas) {
    return;
  }

  offcanvas.addEventListener('beforeshow', function () {
    burger.forEach(function (el) {
      el.dataset.open = true;
    });
    window.scroll(0, 0);
  });
  offcanvas.addEventListener('beforehide', function () {
    burger.forEach(function (el) {
      el.dataset.open = false;
    });
  });
  hideOffcanvasOnNavigationClick(offcanvas);
}