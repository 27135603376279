'use strict';

export function noop() {}
export function scrollToTop(top = 0) {
  window.scrollTo({
    top,
    left: 0,
    behavior: 'smooth'
  });
}
export function isInViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  return bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
}
/**
 * Check if scrollable node is scrolled behind offset or not, react with CSS classes on target element
 * @param {node}    element - where to add CSS classes
 * @param {number}  offset  - pixels of top scroll offset to react
 * @param {string}  yep     - CSS class to set when element is scrolled
 * @param {string}  nope    - CSS class to set when element is not scrolled
 */

export function isWindowScrolled({
  element,
  offset,
  yep = 'is-scrolled',
  nope = 'is-not-scrolled'
}) {
  function test(element) {
    if (window.pageYOffset > offset) {
      element.classList.add(yep);
      element.classList.remove(nope);
    } else {
      element.classList.remove(yep);
      element.classList.add(nope);
    }
  }

  test(element);
  window.addEventListener('scroll', event => {
    test(element);
  });
}