'use strict';

import { Is } from '../is';
import { noop } from '../index';
import { enableBodyScroll, disableBodyScroll } from '../body-scroll-lock';
/**
 * CSS Selector
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors
 * @typedef {string} Selector
 * @example '.foo'
 */

/**
 * Modal with optional form
 * @typedef {object} FormModal
 * @property {Selector} modal - modals CSS selector
 * @property {Selector} [form] - form element inside modal CSS selector
 */

/**
 * Initialize UIKit modals to handle possible forms and overscroll behaviour
 * @param {object} UIkit - instance of UIkit
 * @param {FormModal[]} formModals - array of modals to initialize
 * @param {object} options - additional options
 * @example
 * initModals([
 * 	{ modal: '#feedback-modal', form: '#feedback-form' },
 * 	{ modal: '.special-modal' },
 * ])
 */

/** @enum {string} */

const ModalEvents = {
  BeforeShow: 'beforeshow',
  Shown: 'shown',
  Hide: 'hide',
  Hidden: 'hidden'
};

function disableModalCloseOnDragEvent(UIkit, element) {
  const hideCallback = UIkit.modal(element).hide;
  const body = element.querySelector('.uk-modal-body');

  if (Is.instanceOf(body, HTMLElement)) {
    let memo = null;

    const mouseOverCallback = event => {
      if (event.target === element && memo) {
        // prevent hide modal by deleting function
        UIkit.modal(element).hide = noop;
      }
    };

    element.addEventListener('mouseup', () => {
      setTimeout(() => {
        memo = null;
        UIkit.modal(element).hide = hideCallback;
        element.removeEventListener('mouseover', mouseOverCallback);
      }, 0);
    });
    body.addEventListener('mousedown', event => {
      memo = event.target;
      element.addEventListener('mouseover', mouseOverCallback);
    });
  }
}

export default function initModals(UIkit, formModals = [{
  modal: '[uk-modal]',
  form: '[data-form]'
}], options = {
  bodyScrollLock: true
}) {
  // UIkit beforeshow modal event should be captured in Vue form
  formModals.forEach(({
    modal,
    form
  }) => {
    const modals = document.querySelectorAll(modal);
    modals.forEach(modalElement => {
      if (modalElement) {
        const forms = modalElement.querySelectorAll(form);
        modalElement.addEventListener(ModalEvents.BeforeShow, () => {
          forms.forEach(formElement => {
            if (formElement) {
              formElement.dispatchEvent(new Event(ModalEvents.BeforeShow));
            }
          });
        });
        modalElement.addEventListener(ModalEvents.Shown, event => {
          forms.forEach(formElement => {
            if (formElement) {
              formElement.dispatchEvent(new Event(ModalEvents.Shown));
            }
          });

          if (event.target === modalElement && options.bodyScrollLock) {
            disableBodyScroll(modalElement);
          }
        });
        modalElement.addEventListener(ModalEvents.Hide, () => {
          forms.forEach(formElement => {
            if (formElement) {
              formElement.dispatchEvent(new Event(ModalEvents.Hide));
            }
          });
        });
        modalElement.addEventListener(ModalEvents.Hidden, event => {
          forms.forEach(formElement => {
            if (formElement) {
              formElement.dispatchEvent(new Event(ModalEvents.Hidden));
            }
          });

          if (event.target === modalElement && options.bodyScrollLock) {
            enableBodyScroll(modalElement);
          }
        }); // prevent close modal after mouse drag
        // TODO: workaround until fixed issue https://github.com/uikit/uikit/issues/3963

        disableModalCloseOnDragEvent(UIkit, modalElement);
      }
    });
  });
}