import Vue from 'vue/dist/vue';
export var initSearchForm = function initSearchForm(el) {
  return new Vue({
    el: el,
    data: {
      query: ''
    },
    methods: {
      submit: function submit(e) {
        e.preventDefault();

        if (this.query) {
          window.location.href = "/search/".concat(encodeURIComponent(this.query));
        }
      }
    }
  });
};